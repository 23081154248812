.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.float-right {
  float: right;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  font-size: 15px !important;
  vertical-align: 1px !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50%;
}

.d-inline-flex {
  display: inline-flex;
}

.draw-control {
  top: 4rem;
  left: 0.5rem;
}

.zoom-to-fit-control {
  top: 8rem;
  left: 0.5rem;
}

.fit-control {
  top: 10rem;
  left: 0.5rem;
  color: white;
}

.error-message {
  color: red;
  font-weight: bold;
}

.break-word {
  white-space: normal;
  word-break: break-word;
}

.switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 0px !important;
  margin-top: 2px;
}

.col {
  display: flex;
  flex-direction: column;
}

.meassure-tooltip-control {
  top: 6rem;
  left: 0.5rem;
  cursor: pointer;

  .tooltip-enabled {
    background-color: green;
  }
  &.ol-control button.tooltip-enabled:focus {
    background-color: green;
  }
  &.ol-control button.tooltip-enabled:hover {
    background-color: green;
  }

  &.ol-control button:focus {
    background-color: green;
  }
  &.ol-control button:hover {
    background-color: rgba(0, 60, 136, 0.5);
  }
}

.pointer {
  cursor: pointer;
}

.map {
  .tooltip-measure {
    opacity: 0.5;
    background: #000000;
    color: #FFFFFF;
    font-weight: bold;
  }

  .tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
    opacity: 1;
  }

  .tooltip-measure:before,
  .tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }

  .tooltip-static:before {
    border-top-color: #ffcc33;
  }

  .ol-attribution {
    left: 0.5em;
    right: auto;
  }
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.no-decoration {
  text-decoration: none;
}

.page-header {
  h1 {
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .ant-divider-horizontal {
    width: auto !important;
    min-width: auto !important;
    margin: 24px 1.5rem !important;
  }
}

.page-content {
  padding: 0 1rem 1rem 1rem;
}

// SCROLLBAR STYLES
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
/////

@media(max-width:575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-sm > .table-bordered {
    border: 0
  }
}

.ant-image-preview-img-wrapper {
  padding: 2rem;
}

@media(max-width:767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-md > .table-bordered {
    border: 0
  }
}

@media(max-width:991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-lg > .table-bordered {
    border: 0
  }
}

@media(max-width:1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .table-responsive-xl > .table-bordered {
    border: 0
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive > .table-bordered {
  border: 0
}

.d-none {
  display: none !important;
}